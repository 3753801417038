















import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
  computed: {
    prompt(): {
      ticket: PaymentItem | null;
      text: string;
      callback: (value: boolean) => void;
    } {
      return this.$store.state.ticket.prompting;
    },
    isPrompting(): boolean {
      return this.$store.state.ticket.prompting.ticket === this.item;
    },
  },
});
