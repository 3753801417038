import api from "@/api";
import DataResponse from "@/types/DataResponse";

interface Payload {
  id: string;
  name: string;
  document: string;
  email?: string;
  cellphone?: string;
  operation?: string;
}

async function updatePortador({
  id,
  name,
  document,
  email,
  cellphone,
  operation,
}: Payload): Promise<DataResponse<unknown>> {
  return await api.post(`/api/user/tickets/${id}/change-carrier`, {
    name,
    cpf: document,
    operation,
    email,
    cellphone,
  });
}

export default updatePortador;
