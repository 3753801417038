


















import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
  computed: {
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
    isCourtesy(): boolean {
      return this.item.tipo === "Cortesia";
    },
    hasCarrierBeenModified(): boolean {
      return this.item.carrier_change_date !== null;
    },
    prompt(): {
      ticket: PaymentItem | null;
      text: string;
      callback: (value: boolean) => void;
    } {
      return this.$store.state.ticket.prompting;
    },
    isPrompting(): boolean {
      return this.$store.state.ticket.prompting.ticket === this.item;
    },
  },
});
