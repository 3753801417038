










import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";
import BiometryAction from "@/components/payment/details/actions/BiometryAction.vue";
import SaveAction from "@/components/payment/details/actions/SaveAction.vue";
import EditAction from "@/components/payment/details/actions/EditAction.vue";
import PromptCancelAction from "@/components/payment/details/actions/PromptCancelAction.vue";
import PromptConfirmAction from "@/components/payment/details/actions/PromptConfirmAction.vue";

export default Vue.extend({
  components: {
    BiometryAction,
    SaveAction,
    EditAction,
    PromptCancelAction,
    PromptConfirmAction,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
});
