













































import BiometryService from "@/services/BiometryService";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import User from "@/types/User";
import axios from "axios";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
  computed: {
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    isBiometryPending(): boolean {
      if (!this.hasBiometricAccess) return true;

      const { biometrics } = this.item;

      if (biometrics) {
        return biometrics.photo_status === "pending";
      }

      return true;
    },
    prompt(): {
      ticket: PaymentItem | null;
      text: string;
      callback: (value: boolean) => void;
    } {
      return this.$store.state.ticket.prompting;
    },
    isPrompting(): boolean {
      return this.$store.state.ticket.prompting.ticket === this.item;
    },
    user(): User {
      return this.$store.state.user.data;
    },
    icon(): string {
      return this.isBiometryPending ? "mdi-fingerprint-off" : "mdi-fingerprint";
    },
    title(): string {
      return this.isBiometryPending ? "Biometria Pendente" : "Biometria Cadastrada";
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    async share() {
      try {
        this.$loader.increment("button");

        const service = new BiometryService();

        const data = await service.register(this.item);

        const { photo_status, token } = data;

        if (photo_status == "pending") {
          const url = `${process.env.VUE_APP_SPEEDPASS_ENDPOINT}/foto_dataclick/${token}?callback_url=${process.env.VUE_APP_BASE_URL}/api/speedpass/biometry/${this.order.id}`;

          if (this.isMobile) {
            window.navigator.share({
              title: `Cadastro de Biometria - ${this.order.event.nome}`,
              url,
            });
          } else {
            navigator.clipboard.writeText(url);
            this.$snackbar("Link copiado!", "success");
          }
        } else {
          this.$snackbar("Biometria já está registrada", "success");
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          this.$snackbar(err.response?.data.message, "error");
        } else {
          this.$snackbar((err as Error).message, "error");
        }
      } finally {
        this.$loader.decrement("button");
      }
    },
    async register() {
      try {
        this.$loader.increment("button");

        const service = new BiometryService();

        const data = await service.register(this.item);

        const { photo_status, token } = data;

        if (photo_status == "pending") {
          const url = `${process.env.VUE_APP_SPEEDPASS_ENDPOINT}/foto_dataclick/${token}?callback_url=${process.env.VUE_APP_BASE_URL}/api/speedpass/biometry/${this.order.id}`;
          this.$snackbar("É necessário cadastrar biometria facial", "warning");
          setTimeout(() => window.open(url, "_self"), 1000);
        } else {
          this.$snackbar("Biometria já está registrada", "success");
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          this.$snackbar(err.response?.data.message, "error");
        } else {
          this.$snackbar((err as Error).message, "error");
        }
      } finally {
        this.$loader.decrement("button");
      }
    },
  },
});
