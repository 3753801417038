import api from "@/api";
import BiometricData from "@/types/BiometricData";
import DataResponse from "@/types/DataResponse";
import { AxiosResponse } from "axios";

interface Payload {
  document: string;
  name: string;
  cellphone: string;
  email: string;
  ticket_id: string;
}

export default async function registerUserBiometrics(body: Payload): Promise<DataResponse<BiometricData>> {
  const response: AxiosResponse<DataResponse<BiometricData>> = await api.post(`/api/speedpass/register`, body);
  const { data } = response;
  return data;
}
