












import PixPayment from "@/types/PixPayment";
import Vue from "vue";
import TContainer from "../core/TContainer.vue";
import PixForm from "@/components/Ticket/PaymentForms/PixForm.vue";
import Order from "@/types/Order";
import { DateTime } from "luxon";
import getOrderDetails from "@/api/requests/getOrderDetails";

export default Vue.extend({
  data() {
    return {
      timer: 900,
      timeout: undefined as ReturnType<typeof setTimeout> | undefined,
    };
  },
  beforeMount() {
    if (this.payment) {
      const expiration = DateTime.fromFormat(this.payment.expiration_date, "y-MM-dd HH:mm:ss");
      const diff = expiration.diff(DateTime.now(), ["seconds"]).toObject();

      if (diff.seconds !== undefined) {
        this.timer = diff.seconds;
      }
    }
  },
  mounted() {
    setTimeout(this.checkOrderStatus, 5000);
  },
  components: {
    TContainer,
    PixForm,
  },
  computed: {
    order(): Order {
      return this.$store.state.ticket.selectedOrder;
    },
    payment(): PixPayment {
      return JSON.parse(this.order.cielo_array).data;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    token(): string {
      return this.$store.state.user.token.access_token;
    },
  },
  methods: {
    async checkOrderStatus() {
      try {
        const order = await getOrderDetails(this.order.id);

        this.$store.commit("ticket/setSelectedOrder", order);

        if (order.situacao !== "Confirmado") {
          setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (error) {
        console.log(error);
        this.$snackbar(error, "error");
      }
    },
    redirectExpired() {
      this.$store.commit("cart/reset");
      this.$router.push({
        path: "/ticket/payments",
        query: { status: "Recusado" },
      });
    },
  },
});
