


































import updatePortador from "@/api/requests/updatePortador";
import TBtn from "@/components/core/TBtn.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TPhoneInput from "@/components/core/TPhoneInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import User from "@/types/User";
import axios from "axios";
import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {
    TBtn,
    TTextInput,
    TCpfInput,
    TPhoneInput,
    TEmailInput,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
  },
  computed: {
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    order(): Order & { owner: User } {
      return this.$store.state.ticket.selectedOrder;
    },
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
    rules() {
      const defaultMessage = "Campo obrigatório";
      const general = [(v: string) => !!v || defaultMessage];
      return {
        name: [
          ...general,
          (v: string) => !/\d/.test(v) || "O nome não pode conter números", // has number,
          (v: string) => /[a-zA-Z]/g.test(v.trim()) || defaultMessage, // doesn't have letters
          (v: string) => v.trim().length >= 3 || "O nome deve conter ao menos 3 letras", // less than 3 letters
        ],
        general: general,
      };
    },
    dirty(): boolean {
      return (
        this.form.data.name !== this.item.portador ||
        this.form.data.document.replace(/\D/g, "") !== this.item.cpf.replace(/\D/g, "") ||
        this.form.data.cellphone.replace(/\D/g, "") !== this.item.cellphone.replace(/\D/g, "") ||
        this.form.data.email !== this.item.email
      );
    },
  },
  data() {
    return {
      form: {
        valid: false,
        data: {
          name: "",
          document: "",
          cellphone: "",
          email: "",
        },
      },
    };
  },
  created() {
    this.form.data.name = this.item.portador;
    this.form.data.document = this.item.cpf;
    this.form.data.cellphone = this.item.cellphone;
    this.form.data.email = this.item.email;
  },
  methods: {
    async submit() {
      this.$loader.increment("button");

      try {
        if (!this.form.valid) {
          this.$snackbar("Verifique se todos campos estão corretamente preenchidos", "warning");
          return;
        }

        if (!this.dirty) {
          this.$emit("submit", {
            name: this.item.portador,
            cpf: this.item.cpf,
            email: this.item.email,
            cellphone: this.item.cellphone,
          });

          return;
        }

        await updatePortador({
          id: this.item.id,
          name: this.form.data.name,
          document: this.form.data.document.replace(/\D/g, ""),
          operation: "edit",
          email: this.form.data.email ?? undefined,
          cellphone: this.form.data.cellphone.replace(/\D/g, ""),
        });

        this.$snackbar("Portador alterado com sucesso", "success");

        this.$emit("submit", this.form.data);
      } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error)) {
          console.log(error.response);
          this.$snackbar(error.response?.data.message, "error");
        } else if (error instanceof Error) {
          this.$snackbar(error.message, "error");
        }
      } finally {
        this.$loader.decrement("button");
      }
    },
  },
});
