













































































import updatePortador from "@/api/requests/updatePortador";
import TBtn from "@/components/core/TBtn.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TPhoneInput from "@/components/core/TPhoneInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import cellphone from "@/filters/cellphone";
import cpf from "@/filters/cpf";
import currency from "@/filters/currency";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import axios from "axios";
import { DateTime } from "luxon";
import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {
    TPhoneInput,
    TCpfInput,
    TEmailInput,
    TTextInput,
    TBtn,
  },
  props: {
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
  },
  computed: {
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    isCourtesy(): boolean {
      return this.item.tipo === "Cortesia";
    },
    rules() {
      const defaultMessage = "Campo obrigatório";
      const general = [(v: string) => !!v || defaultMessage];
      return {
        name: [
          ...general,
          (v: string) => !/\d/.test(v) || "O nome não pode conter números", // has number,
          (v: string) => /[a-zA-Z]/g.test(v.trim()) || defaultMessage, // doesn't have letters
          (v: string) => v.trim().length >= 3 || "O nome deve conter ao menos 3 letras", // less than 3 letters
        ],
        general: general,
      };
    },
    dirty(): boolean {
      return (
        this.editing.name !== this.item.portador ||
        this.editing.cpf.replace(/\D/g, "") !== this.item.cpf.replace(/\D/g, "") ||
        this.editing.cellphone.replace(/\D/g, "") !== this.item.cellphone.replace(/\D/g, "") ||
        this.editing.email !== this.item.email
      );
    },
  },
  data() {
    return {
      editing: {
        name: "",
        cpf: "",
        email: "",
        cellphone: "",
        operation: "edit" as "edit" | "register",
      },
    };
  },
  created() {
    this.editing.name = this.item.portador;
    this.editing.cpf = this.item.cpf;
    this.editing.cellphone = this.item.cellphone;
    this.editing.email = this.item.email;
  },
  methods: {
    print(item: PaymentItem | null, sectorId: string | null = null) {
      this.$store.dispatch("tickets/print", { item, sectorId });
    },
    async submit() {
      this.$loader.increment("button");

      try {
        const fields = [
          this.$refs["cpf-field"],
          this.$refs["name-field"],
          this.$refs["email-field"],
          this.$refs["cellphone-field"],
        ] as (Vue & { validate(): boolean })[];

        const results = fields.filter((f) => f !== undefined).map((f) => f.validate());

        const valid = results.every((f) => f);

        if (!valid) {
          this.$snackbar("Verifique se todos campos estão corretamente preenchidos", "warning");
          return;
        }

        if (!this.dirty) {
          this.$emit("submit", {
            name: this.item.portador,
            cpf: this.item.cpf,
            email: this.item.email,
            cellphone: this.item.cellphone,
          });

          return;
        }

        await updatePortador({
          id: this.item.id,
          name: this.editing.name,
          document: this.editing.cpf.replace(/\D/g, ""),
          operation: this.editing.operation,
          email: this.editing.email ?? undefined,
          cellphone: this.editing.cellphone?.replace(/\D/g, ""),
        });

        this.item.carrier_change_date = DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");
        this.$snackbar("Portador alterado com sucesso", "success");

        this.$emit("submit", this.editing);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          this.$snackbar(error.response?.data.message, "error");
        } else if (error instanceof Error) {
          this.$snackbar(error.message, "error");
        }
      } finally {
        this.$loader.decrement("button");
      }
    },
    currency,
    cellphone,
    cpf,
  },
});
