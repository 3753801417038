import setCarrierChangeDate from "@/api/requests/setCarrierChangeDate";
import store from "@/store";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import { DateTime } from "luxon";

export default class VoucherService {
  bundle(order: Order, sectorId: string): void {
    const token = store.state.user.token;

    if (token === null) throw new Error("Usuário não autenticado");

    const url = `${process.env.VUE_APP_BASE_URL}/api/user/tickets/${order.id}/print?access_token=${token.access_token}&sector_id=${sectorId}`;

    window.open(url, "_blank")?.focus();
  }

  async single(order: Order, item: PaymentItem): Promise<void> {
    const token = store.state.user.token;

    if (token === null) throw new Error("Usuário não autenticado");

    if (item.tipo === "Cortesia" && item.carrier_change_date === null) {
      item.carrier_change_date = await this.markAsPrinted(item);
    }

    const url = `${process.env.VUE_APP_BASE_URL}/api/user/tickets/${order.id}/print?access_token=${token.access_token}&item=${item.id}`;

    window.open(url, "_blank")?.focus();
  }

  async markAsPrinted(item: PaymentItem): Promise<string> {
    await setCarrierChangeDate(item.id);
    return DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");
  }
}
