















import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import User from "@/types/User";
import Vue from "vue";
import PaymentItemListGroup from "@/components/payment/details/desktop/PaymentItemListGroup.vue";
import PrintingRequiredAlert from "../PrintingRequiredAlert.vue";

export default Vue.extend({
  components: {
    PaymentItemListGroup,
    PrintingRequiredAlert,
  },
  mounted() {
    for (let i = 0; i < this.groupedTickets.length; i++) {
      this.selected.push(i);
    }
  },
  data() {
    return {
      selected: [] as number[],
    };
  },
  computed: {
    order(): Order & { owner: User } {
      return this.$store.state.ticket.selectedOrder;
    },
    groupedTickets(): PaymentItem[][] {
      const dictionary: { [key: string]: PaymentItem[] } = {};

      for (const ticket of this.order.items) {
        const sectorName = ticket.secao_id;
        if (!dictionary[sectorName]) {
          dictionary[sectorName] = [ticket];
        } else {
          dictionary[sectorName].push(ticket);
        }
      }
      return [...Object.values(dictionary)];
    },
    isPrintingRequired(): boolean {
      const { event } = this.order;
      return event.impressao_obrigatoria;
    },
  },
});
