





























import cellphone from "@/filters/cellphone";
import cpf from "@/filters/cpf";
import currency from "@/filters/currency";
import pad from "@/filters/pad";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import TicketConfiguration from "@/types/TicketConfiguration";
import Vue, { PropType } from "vue";
import PaymentItemInfo from "@/components/payment/details/mobile/PaymentItemInfo.vue";
import PaymentItemEditForm from "@/components/payment/details/mobile/PaymentItemEditForm.vue";
import PaymentItemActions from "@/components/payment/details/PaymentItemActions.vue";
import PaymentItemPrompt from "../desktop/PaymentItemPrompt.vue";

export default Vue.extend({
  components: {
    PaymentItemInfo,
    PaymentItemEditForm,
    PaymentItemActions,
    PaymentItemPrompt,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
  computed: {
    config(): TicketConfiguration {
      return this.order.event.configuration as TicketConfiguration;
    },
    isCourtesy() {
      return this.item.tipo === "Cortesia";
    },
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
    carrierOrCourtesyName() {
      if (this.item.portador) {
        return this.item.portador;
      }
      return this.item.nome_cortesia;
    },
  },
  methods: {
    cpf,
    cellphone,
    pad,
    currency,
    handleSubmit({
      name,
      document,
      email,
      cellphone,
    }: {
      name: string;
      document: string;
      email: string;
      cellphone: string;
    }) {
      if (this.editing.ticket === null) return;

      this.item.portador = name;
      this.item.cpf = document;
      this.item.email = email;
      this.item.cellphone = cellphone;

      this.editing.ticket = null;
    },
  },
});
