






















import Order from "@/types/Order";
import User from "@/types/User";
import Vue from "vue";
import PrintingRequiredAlert from "@/components/payment/details/PrintingRequiredAlert.vue";
import PaymentItemComponent from "@/components/payment/details/mobile/PaymentItem.vue";
import PaymentItem from "@/types/PaymentItem";

export default Vue.extend({
  components: {
    PrintingRequiredAlert,
    PaymentItemComponent,
  },
  computed: {
    order(): Order & { owner: User } {
      return this.$store.state.ticket.selectedOrder;
    },
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
  },
});
