
















import Order from "@/types/Order";
import User from "@/types/User";
import Vue from "vue";

export default Vue.extend({
  computed: {
    order(): Order & { owner: User } {
      return this.$store.state.ticket.selectedOrder;
    },
    isPrintingRequired(): boolean {
      const { event } = this.order;
      return event.impressao_obrigatoria;
    },
  },
});
