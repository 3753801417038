





























































































import Vue, { PropType } from "vue";
import Order from "@/types/Order";
import { DateTime } from "luxon";
import TEventLogo from "@/components/core/Events/TEventLogo.vue";
import currency from "@/filters/currency";
import TicketConfiguration from "@/types/TicketConfiguration";

export default Vue.extend({
  name: "PaymentInfoCard",
  data() {
    return {
      luxon: DateTime,
    };
  },
  props: {
    order: {
      type: Object as PropType<Order>,
    },
  },
  components: {
    TEventLogo,
  },
  methods: {
    formatDate(date: string, format: string, input_format = "y-MM-dd HH:mm:ss"): string {
      const parsed = DateTime.fromFormat(date, input_format);
      return parsed.toFormat(format);
    },
    getPaymentTypeName(type: number) {
      return ["", "Cartão de Crédito", "Boleto", "Débito Online", "Cartão de Débito", "PIX", "Cortesia"][type];
    },
    currency,
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    configuration(): TicketConfiguration {
      return this.order.event.configuration!;
    },
    paymentStatus(): string {
      switch (this.order.situacao) {
        case "Cancelado":
          return "Cancelado";
        case "Confirmado":
          return "Pago";
        case "Cortesia":
          return "Cortesia";
        case "Recusado":
          return "Falha no pagamento";
        default:
          return "Aguardando Pagamento";
      }
    },
    chipColor(): string {
      switch (this.order.situacao) {
        case "Cancelado":
          return "red";
        case "Confirmado":
        case "Cortesia":
          return "green";
        case "Recusado":
          return "red";
        default:
          return "blue";
      }
    },
    amount(): number {
      return this.order.items.reduce((acc, item) => (acc += item.valor), 0);
    },
    fees(): number {
      return this.order.items.reduce((acc, item) => (acc += parseFloat(item.taxa_conveniencia)), 0);
    },
  },
});
