










import TAlert from "@/components/core/TAlert.vue";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {
    TAlert,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
  },
  computed: {
    prompt(): {
      ticket: PaymentItem | null;
      text: string;
      response: boolean | null;
    } {
      return this.$store.state.ticket.prompting;
    },
    isPrompting(): boolean {
      return this.$store.state.ticket.prompting.ticket === this.item;
    },
    text(): string {
      return this.prompt.text;
    },
  },
});
