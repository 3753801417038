




























































































































import cellphone from "@/filters/cellphone";
import cpf from "@/filters/cpf";
import currency from "@/filters/currency";
import pad from "@/filters/pad";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import TicketConfiguration from "@/types/TicketConfiguration";
import User from "@/types/User";
import Vue, { PropType } from "vue";
import Barcode from "vue-barcode";
import QrCode from "qrcode.vue";
import TBtn from "@/components/core/TBtn.vue";
import ViewAction from "../actions/ViewAction.vue";

export default Vue.extend({
  components: {
    Barcode,
    QrCode,
    TBtn,
    ViewAction,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
  },
  computed: {
    order(): Order & { owner: User } {
      return this.$store.state.ticket.selectedOrder;
    },
    config(): TicketConfiguration {
      return this.order.event.configuration as TicketConfiguration;
    },
    isCourtesy() {
      return this.item.tipo === "Cortesia";
    },
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
    carrierOrCourtesyName() {
      if (this.item.portador) {
        return this.item.portador;
      }
      return this.item.nome_cortesia;
    },
    eticketAllowed(): boolean {
      if (this.order.event.access_type === "biometric") return false;

      const allowed = ["voucher.acesso"];

      if (this.order.event.configuration == null) return false;

      return allowed.includes(this.order.event.configuration.template_voucher);
    },
    isQrCodeVoucher(): boolean {
      return ["templateImpressao.jogo-padrao-qrcode"].includes(this.order.event.configuration!.template_ingresso);
    },
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    isDarkTheme(): boolean {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    },
  },
  methods: {
    cpf,
    cellphone,
    pad,
    currency,
  },
});
