
























import Vue from "vue";
import Order from "@/types/Order";
import PaymentInfoCard from "@/components/payment/details/PaymentInfoCard.vue";
import TCategoryDivider from "@/components/core/TCategoryDivider.vue";
import PendingOrderForm from "@/components/Ticket/PendingOrderForm.vue";
import getPaymentDetails from "@/api/requests/getPaymentDetails";
import DesktopPaymentItemList from "@/components/payment/details/desktop/PaymentItemList.vue";
import MobilePaymentItemList from "@/components/payment/details/mobile/PaymentItemList.vue";

export default Vue.extend({
  name: "PaymentDetails",
  data() {
    return {};
  },
  components: {
    PaymentInfoCard,
    TCategoryDivider,
    PendingOrderForm,
    DesktopPaymentItemList,
    MobilePaymentItemList,
  },
  computed: {
    order(): Order {
      return this.$store.state.ticket.selectedOrder;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
  created() {
    this.fetchPaymentDetails();
    this.$store.state.ticket.editing.ticket = null;
  },
  methods: {
    async fetchPaymentDetails() {
      try {
        const data = await getPaymentDetails(this.$route.params.id);
        this.$store.commit("ticket/setSelectedOrder", data);
      } catch (error) {
        this.$snackbar("Erro ao carregar os detalhes do pagamento. Tente novamente", "error");
      }
    },
  },
});
