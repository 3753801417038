
















import VoucherService from "@/services/VoucherService";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
  },
  computed: {
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
    isCourtesy(): boolean {
      return this.item.tipo === "Cortesia";
    },
    title(): string {
      return this.isCourtesy ? "Salvar Cortesia" : "Salvar Ingresso";
    },
    prompt(): {
      ticket: PaymentItem | null;
      text: string;
      callback: (value: boolean) => void;
    } {
      return this.$store.state.ticket.prompting;
    },
    isPrompting(): boolean {
      return this.$store.state.ticket.prompting.ticket === this.item;
    },
  },
  methods: {
    show() {
      try {
        this.$store.dispatch("ticket/prompt", {
          text: "ATENÇÃO: Esta é uma ação irreversível. Esteja ciente de que o portador não poderá ser nomeado após a cortesia ser visualizada!",
          callback: async (value: boolean) => {
            if (!value) {
              this.$store.dispatch("ticket/clearPrompt");
              return;
            }

            this.$loader.increment("button");
            this.item.carrier_change_date = await new VoucherService().markAsPrinted(this.item);
            this.$store.dispatch("ticket/clearPrompt");
            this.$loader.decrement("button");
          },
          item: this.item,
        });
      } catch (error) {
        if (error instanceof Error) {
          this.$snackbar(error.message, "error");
        }
      }
    },
  },
});
