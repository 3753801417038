<template>
  <v-row>
    <v-col class="d-flex align-center px-0">
      <v-icon v-if="icon" color="primary" :class="{ rotateIcon: rotateIcon }" large>
        {{ icon }}
      </v-icon>

      <span v-text="category" class="primary--text text-h6 mx-2" />

      <v-divider class="primary" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TCategoryDivider",

  props: {
    icon: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    rotateIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.rotateIcon {
  transform: rotate(90deg);
}
</style>
