



















































































import Vue, { PropType } from "vue";
import PaymentItem from "@/types/PaymentItem";
import Order from "@/types/Order";
import PaymentItemComponent from "@/components/payment/details/desktop/PaymentItem.vue";
import PaymentItemEditForm from "./PaymentItemEditForm.vue";
import TAlert from "@/components/core/TAlert.vue";
import PaymentItemPrompt from "./PaymentItemPrompt.vue";
import VoucherService from "@/services/VoucherService";
import Sector from "@/types/Sector";

export default Vue.extend({
  components: {
    PaymentItemComponent,
    PaymentItemEditForm,
    PaymentItemPrompt,
    TAlert,
  },
  props: {
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
    items: {
      required: true,
      type: Array as PropType<PaymentItem[]>,
    },
  },
  computed: {
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    editing(): { ticket: PaymentItem | null; action: "edit" | "register" } {
      return this.$store.state.ticket.editing;
    },
    isEditing(): boolean {
      return this.editing.ticket !== null;
    },
  },
  methods: {
    isCourtesy(ticket: PaymentItem) {
      return ticket.tipo === "Cortesia";
    },
    print() {
      const service = new VoucherService();
      service.bundle(this.order, this.items[0].setor_id);
    },
    handleSubmit({ name, cpf, email, cellphone }: { name: string; cpf: string; email: string; cellphone: string }) {
      if (this.editing.ticket === null) return;

      this.editing.ticket.portador = name;
      this.editing.ticket.cpf = cpf;
      this.editing.ticket.email = email;
      this.editing.ticket.cellphone = cellphone;

      this.editing.ticket = null;
    },
  },
});
