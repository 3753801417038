import api from "@/api";
import DataResponse from "@/types/DataResponse";
import { AxiosResponse } from "axios";

export default async function setCarrierChangeDate(ticket_id: string): Promise<DataResponse<unknown>> {
  const response: AxiosResponse<DataResponse<unknown>> = await api.put(
    `/api/user/tickets/${ticket_id}/carrier-change-date`
  );
  return response.data;
}
