
































import cellphone from "@/filters/cellphone";
import cpf from "@/filters/cpf";
import currency from "@/filters/currency";
import Order from "@/types/Order";
import PaymentItem from "@/types/PaymentItem";
import Vue, { PropType } from "vue";
import PaymentItemActions from "@/components/payment/details/PaymentItemActions.vue";

export default Vue.extend({
  components: {
    PaymentItemActions,
  },
  props: {
    order: {
      required: true,
      type: Object as PropType<Order>,
    },
    item: {
      required: true,
      type: Object as PropType<PaymentItem>,
    },
  },
  computed: {
    hasBiometricAccess(): boolean {
      if (this.order.event.access_type === undefined) return false;

      return ["biometric", "hybrid"].includes(this.order.event.access_type);
    },
    nameOrCourtesyName() {
      return this.item.portador ?? this.item.nome_cortesia;
    },
    isCourtesy(): boolean {
      return this.item.tipo === "Cortesia";
    },
  },
  methods: {
    currency,
    cellphone,
    cpf,
  },
});
