import registerUserBiometrics from "@/api/requests/registerUserBiometrics";
import BiometricData from "@/types/BiometricData";
import PaymentItem from "@/types/PaymentItem";

export default class BiometryService {
  async register(ticket: PaymentItem): Promise<BiometricData> {
    const { cpf: document, portador: name, cellphone, email, id: ticket_id } = ticket;

    const validation = [name, document, cellphone, email, ticket_id].every((i) => typeof i === "string");

    if (!validation) throw new Error("Confira se você preencheu todos os dados do portador");

    const response = await registerUserBiometrics({ name, document, cellphone, email, ticket_id });

    return response.data;
  }
}
